<template>
  <main class="main profile-page page-inside" v-if="isAuth">
    <div class="main-wrapper">
      <div class="section profile-section">
        <div class="wrapper">
          <div class="profile-wrapper">
            <div class="profile-nav">
              <div class="nav__item">
                <router-link class="desc" to="/profile/details">
                  {{ $t("My profile") }}
                </router-link>
              </div>
              <div class="nav__item">
                <router-link class="desc" to="/profile/password">
                  {{ $t("Password") }}
                </router-link>
              </div>
              <div class="nav__item">
                <router-link class="desc" to="/profile/history">
                  {{ $t("Order history") }}
                </router-link>
              </div>
              <div class="nav__item">
                <a class="desc" @click="$emit('logout')">
                  {{ $t("LogOut") }}
                </a>
              </div>
            </div>
            <div class="form profile-form">
              <div class="profile-form-wrapper">
                <div class="profile-form-wrapper" v-if="this.$route.params.page == 'details'">
                  <div class="title small">{{ $t("Personal Information") }}</div>
                  <div class="input-list">
                    <div class="input-container">
                      <div class="desc"><span class="red">*</span> {{ $t("First name") }}:</div>
                      <input type="text" placeholder="First name" v-model="name" />
                    </div>
                    <div class="input-container">
                      <div class="desc"><span class="red">*</span> {{ $t("Last name") }}:</div>
                      <input type="text" placeholder="Last name" v-model="surname" />
                    </div>
                    <div class="input-container">
                      <div class="desc"><span class="red">*</span> {{ $t("E-mail") }}:</div>
                      <input type="email" placeholder="E-mail" v-model="email" />
                    </div>
                    <div class="input-container" v-if="countryOptionsIso && countryOptionsIso.length">
                      <div class="desc"><span class="red">*</span> {{ $t("Phone") }}:</div>
                      <MazPhoneNumberInput v-model="phone" v-model:country-code="countryCodeName"
                        :defaultCountryCode="convertedShortName" show-code-on-list :only-countries="countryOptionsIso"
                        @update="phoneDataUpdate($event)" />
                    </div>
                  </div>

                  <div class="title small">{{ $t("Additional data") }}</div>
                  <div class="input-list">
                    <div class="input-container country-select-container">
                      <div class="desc"><span class="red">*</span> {{ $t("Country") }}:</div>
                      <div class="select-wrapper">
                        <select v-model="countryId" :class="{ 'empty': !countryId }">
                          <option value="" disabled selected>{{ $t("Country") }}</option>
                          <option v-for="option in countryOptions" :key="option.id" :value="option.id">
                            {{ option.title }}
                          </option>
                        </select>
                        <span v-if="countryId" class="clear-icon" @click="clearCountry">&times;</span>
                      </div>
                    </div>

                    <div class="input-container">
                      <div class="desc"><span class="red">*</span> {{ $t("City") }}:</div>
                      <input type="text" :placeholder="$t('City')" v-model="city" />
                    </div>
                    <div class="input-container">
                      <div class="desc"><span class="red">*</span> {{ $t("Address") }}:</div>
                      <input type="text" :placeholder="$t('Address')" v-model="address" />
                    </div>
                    <div class="input-container">
                      <div class="desc"><span class="red">*</span> {{ $t("Post Code") }}:</div>
                      <input type="text" :placeholder="$t('Post Code')" v-model="zip" />
                    </div>

                    <div class="input-container">
                      <div class="desc"><span class="red">*</span> Steam ID:</div>
                      <input type="text" placeholder="Steam ID" v-model="steamId" />
                    </div>

                    <button class="button" @click="updateProfileData">
                      <span>{{ $t("Save changes") }}</span>
                    </button>
                    <Transition>
                      <div class="desc red" v-if="profileUpdateErrorMessaage">{{ profileUpdateErrorMessaage }}</div>
                    </Transition>
                    <Transition>
                      <div class="desc green" v-if="profileUpdateSuccessMessaage">{{ profileUpdateSuccessMessaage }}
                      </div>
                    </Transition>
                  </div>

                  <div class="title small">{{ $t("Referral Code") }}</div>
                  <div class="input-list">

                    <div class="input-container">
                      <!-- <div class="desc">{{ $t("Referral Code") }}:</div> -->
                      <input type="text" :placeholder="$t('Write your referral code')" v-model="referralCode" />
                    </div>

                    <button class="button dark" @click="updateReferralCode">
                      <span>{{ $t("Save changes") }}</span>
                    </button>

                    <transition>
                      <div class="desc red" v-if="referralUpdateErrorMessage">{{ referralUpdateErrorMessage }}</div>
                    </transition>
                    <transition>
                      <div class="desc green" v-if="referralUpdateSuccessMessage">{{ referralUpdateSuccessMessage }}
                      </div>
                    </transition>
                  </div>


                </div>

                <div class="profile-form-wrapper" v-if="this.$route.params.page == 'password'">
                  <div class="title small">{{ $t("Change Password") }}</div>
                  <div class="input-list">
                    <div class="input-container">
                      <div class="desc"><span class="red">*</span> {{ $t("Current password") }}:</div>
                      <input type="text" placeholder="Current password" v-model="curPass" />
                    </div>
                    <div class="input-container long">
                      <div class="desc"><span class="red">*</span> {{ $t("New password") }}:</div>
                      <input type="text" placeholder="New password" v-model="newPass" />
                    </div>
                    <div class="input-container long">
                      <div class="desc"><span class="red">*</span> {{ $t("Confirm password") }}:</div>
                      <input type="text" placeholder="Confirm password" v-model="repeatPass" />
                    </div>
                    <div class="pass-rules">
                      <div class="desc bold">{{ $t("Password must contain") }}:</div>
                      <div class="desc">
                        - {{ $t(`At least 6 characters, 1 upper case letter (A-Z), 1 lower case letter (a - z), 1
                        number(0 - 9)`) }}
                      </div>
                    </div>
                    <button class="button dark" @click="updatePassword">
                      <span>{{ $t("Save") }}</span>
                    </button>
                    <Transition>
                      <div class="desc red" v-if="passUpdateErrorMessaage">{{ passUpdateErrorMessaage }}</div>
                    </Transition>
                    <Transition>
                      <div class="desc green" v-if="passUpdateSuccessMessaage">{{ passUpdateSuccessMessaage }}</div>
                    </Transition>
                  </div>
                </div>
                <div class="profile-form-wrapper" v-if="this.$route.params.page == 'history'">
                  <div class="title small">{{ $t("Your Orders") }}</div>
                  <div class="desc empty-desc" v-if="!orderHistory">{{ $t('Empty') }}</div>
                  <div class="profile-table" v-if="orderHistory">
                    <div class="profile-table-wrapper profile-form-wrapper">
                      <div class="table-container">
                        <table>
                          <thead>
                            <tr>
                              <td>{{ $t("ID") }}</td>
                              <td>{{ $t("Date/time") }}</td>
                              <td>{{ $t("Skin name") }}</td>
                              <td>{{ $t("Total") }}</td>
                              <td>{{ $t("Status") }}</td>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(item, i) in orderHistory" :key="i">
                              <td>{{ item.id }}</td>
                              <td>{{ item.created_at_foramtted }}</td>
                              <td>{{ item.title }}</td>
                              <td>{{ item.sum }} {{ item.currency }}</td>
                              <td>{{ $t("Offer declined") }}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>

export default {
  name: 'ProfilePage',
  props: {
    isAuth: {
      type: Boolean,
      required: true
    },
    orderHistory: {
      type: Array,
      required: true
    },
  },
  components: {
  },
  data: function () {
    return {
      email: '',
      name: '',
      surname: '',
      phone: '',
      profileUpdateSuccessMessaage: '',
      profileUpdateErrorMessaage: '',
      curPass: '',
      newPass: '',
      repeatPass: '',
      passUpdateSuccessMessaage: '',
      passUpdateErrorMessaage: '',
      phoneFull: "",
      convertedShortName: '',
      countryCode: '',
      countryCodeList: [],
      profilePhone: '',
      countryOptions: [],
      address: '',
      city: '',
      zip: '',
      countryId: '',
      steamId: '',
      countryCodeName: '',
      countryOptionsIso: [],

      referralUpdateSuccessMessage: '',
      referralUpdateErrorMessage: '',
      referralCode: '',
    }
  },
  mounted() {
    this.getProfile();
    this.getCountries();
  },
  methods: {
    clearCountry() {
      this.countryId = '';
    },
    formatDate(num) {
      let objectDate = new Date(num * 1000);
      let day = objectDate.getDate();
      if (day < 10) {
        day = "0" + day;
      }
      let month = objectDate.getMonth() + 1;
      if (month < 10) {
        month = `0${month}`;
      }
      let year = objectDate.getFullYear();

      function padTo2Digits(x) {
        return String(x).padStart(2, "0");
      }
      let hoursAndMinutes = padTo2Digits(objectDate.getHours()) + ":" + padTo2Digits(objectDate.getMinutes());
      let format = day + "." + month + "." + year + " " + hoursAndMinutes;

      return format;
    },
    getCountries() {
      this.$http.get(process.env.VUE_APP_API + 'countries')
        .then((res) => {
          this.countryOptions = res.data.payload;
          this.countryOptionsIso = res.data.payload.map(obj => obj.iso);
        })
        .catch((res) => {
          if (res.response.status === 401) {
            this.$emit('openSignIn');
          }
        })
    },
    getProfile() {
      let self = this;
      this.$http.get(process.env.VUE_APP_API + 'user/profile')
        .then((res) => {
          if (res.status == 200) {
            self.email = res.data.email;
            self.name = res.data.name;
            self.surname = res.data.surname;
            self.address = res.data.address;
            self.city = res.data.city;
            self.zip = res.data.zip;
            self.countryId = res.data.country;
            self.phone = res.data.phone.replace(/^[^\s]*\s/, '');
            self.profilePhone = res.data.phone;
            self.steamId = res.data.steamId;
            self.referralCode = res.data.referral_code;
            self.getAllCountryCodes();
          }
        })
        .catch((res) => {
          if (res.response.status === 401) {
            this.$emit('openSignIn');
          }
        })
    },
    getAllCountryCodes() {
      this.$http.get(process.env.VUE_APP_API + 'phone-codes')
        .then((res) => {
          if (res.data.status == "OK") {
            this.countryCodeList = res.data.payload;
            this.countryCode = this.getSymbolsBeforeSpace(this.profilePhone).replace('+', '');
            this.getShortNameByPhoneCode(this.countryCode, this.countryCodeList);
            this.countryCodeName = this.convertedShortName;
          }
        })
        .catch((res) => {
          if (res.response.status === 401) {
            this.$emit('openSignIn');
          }
        })
    },
    getShortNameByPhoneCode(phoneCode, codeList) {
      for (let i = 0; i < codeList.length; i++) {
        const countryCode = Object.keys(codeList[i])[0];
        const codeValue = codeList[i][countryCode].code
        if (codeValue == phoneCode) {
          this.convertedShortName = countryCode;
        }
      }
    },
    getSymbolsBeforeSpace(inputString) {
      const regex = /^[^\s]+/;
      const match = inputString.match(regex);

      if (match) {
        return match[0];
      } else {
        return ""; // Return an empty string if no symbols are found
      }
    },
    phoneDataUpdate(e) {
      this.phoneFull = e.formatInternational;
    },
    updateProfileData() {
      let profileData = {
        "email": this.email,
        "name": this.name,
        "surname": this.surname,
        "phone": this.phoneFull,
        "steamId": this.steamId,
        "country_id": this.countryId,
        "city": this.city,
        "address": this.address,
        "zip": this.zip,
        "steam_id": this.steamId,
      }
      this.$http.put(process.env.VUE_APP_API + 'user/profile', profileData)
        .then((res) => {
          if (res.data.status == "OK") {
            this.profileUpdateSuccessMessaage = 'Success';
            this.profileUpdateErrorMessaage = '';
            setTimeout(() => {
              this.profileUpdateSuccessMessaage = '';
            }, 3000);
          }
        })
        .catch((res) => {
          if (res.response.status === 401) {
            this.$emit('openSignIn');
          }
          this.profileUpdateErrorMessaage = res.response.data.message;
          this.profileUpdateSuccessMessaage = '';
          setTimeout(() => {
            this.profileUpdateErrorMessaage = '';
          }, 3000);
        })
    },
    updatePassword() {
      let passwordList = {
        "cur_password": this.curPass,
        "new_password": this.newPass,
        "repeat_password": this.repeatPass
      }
      this.$http.post(process.env.VUE_APP_API + 'user/change-password', passwordList)
        .then((res) => {
          if (res.data.status == "OK") {
            this.passUpdateSuccessMessaage = 'Success';
            this.curPass = '',
              this.newPass = '',
              this.repeatPass = '';
            setTimeout(() => {
              this.passUpdateSuccessMessaage = '';
            }, 3000);
          }
        })
        .catch((res) => {
          if (res.response.status === 401) {
            this.$emit('openSignIn');
          }
          this.passUpdateErrorMessaage = res.response.data.message;
          setTimeout(() => {
            this.passUpdateErrorMessaage = '';
          }, 3000);
        })
    },
    updateReferralCode() {
      let referralData = {
        "referral_code": this.referralCode
      };
      this.$http.put(process.env.VUE_APP_API + 'user/referral-code', referralData)
        .then((res) => {
          if (res.data.status == "OK") {
            this.referralUpdateErrorMessage = '';
            this.referralUpdateSuccessMessage = 'Referral code updated successfully!';
            setTimeout(() => {
              this.referralUpdateSuccessMessage = '';
            }, 3000);
          }
        })
        .catch((res) => {
          if (res.response.status === 401) {
            this.$parent.openSignInModal();
          }
          this.referralUpdateErrorMessage = res.response.data.message;
          this.referralUpdateSuccessMessage = '';
          setTimeout(() => {
            this.referralUpdateErrorMessage = '';
          }, 3000);
        });
    }
  }
}
</script>